import React, { useState, useEffect } from "react";
import {
    Button,
    Input,
    Alert,
    AlertIcon,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import { useAuth } from "../../contexts/AuthContext";
import ApiFetch from "../../utils/api";

const EnterPasswordDialog = ({ isOpen = false, setIsOpen, onSubmit }) => {
    const cancelRef = React.useRef()
    const [password, setPassword] = useState('')

    const onClose = () => {
        setPassword("")
        setIsOpen(false)
    }

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Please enter your password
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <p>To update your current email you need to enter your password.</p>
                        <Input
                            autoFocus={true}
                            type="password"
                            name="password"
                            className="mb-2"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="password"
                        // ref={register}
                        />
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} variant="red" onClick={onClose} >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onSubmit(password)
                                onClose()
                            }}
                            ml={3}
                            disabled={!password || password.length < 6}
                        >
                            Continue
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

const ProfileSettings = ({ }) => {
    const { currentUser, updateEmail, updateUser, resetPassword } = useAuth()
    const [emailField, setEmailField] = useState(currentUser?.email ?? '')
    const [emailHasChanges, setEmailHasChanges] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [showEnterPassword, setShowEnterPassword] = useState(false)

    useEffect(() => {
        setEmailHasChanges(emailField !== currentUser?.email)
    }, [emailField])

    const onUserEmailSubmit = (e) => {
        e.preventDefault()
        error && setError(false)
        setShowEnterPassword(true);
    }

    const doEmailUpdate = (password) => {
        setLoading(true)
        updateEmail(emailField, password).then(async (result) => {
            if (result?.code) {
                // Here we have also the email validation errors!
                setError(result.message)
            } else {
                // Update backend DB
                const result = await ApiFetch({
                    method: 'PUT',
                    path: `/auth/email/${emailField}`,
                    body: {
                        subnum: currentUser.shopData.sub_num,
                        oldEmail: currentUser.email
                    }
                })
                if (result.success) {
                    updateUser({
                        ...currentUser,
                        email: emailField
                    })
                    setMessage(`Email successfully updated`)
                }
                else if (result.error) {
                    console.log("result.error: ", result.error)
                    // Fallback update firebase to previous email
                    await updateEmail(currentUser.email, password)
                    setError(result.error.message)
                }
            }
        }).catch((error) => {
            console.error("update error: ", error)
        }).finally(() => {
            setLoading(false)
        })
    }

    const onResetPassword = async e => {
        e.preventDefault()
        await resetPassword(currentUser.email)
        setMessage(`We sent you a reset password link to ${currentUser.email}`)
    }

    React.useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage('')
            }, 2000)
        }
    }, [message])

    return (
        <div
            className={
                'bg-white rounded-[20px] px-10 md:px-10  py-8 md:py-8 md:w-6/12 sm:11/12 mt-4 mb-4'
            }
        >
            <h1 className="uppercase text-lightBlue text-l mb-8">Profile Settings</h1>
            <EnterPasswordDialog
                isOpen={showEnterPassword}
                setIsOpen={setShowEnterPassword}
                onSubmit={doEmailUpdate}
            />
            <form>

                <div className={`flex justify-between mb-1.5 items-center`}>
                    <div className="font-semibold text-[14px]">Email:</div>
                    <div className="md:w-[400px]" style={{ minWidth: '65%', maxWidth: '65%' }}>
                        <Input
                            placeholder="email@email.com"
                            name="email"
                            value={emailField}
                            onChange={e => {
                                setError(null)
                                setEmailField(e.target.value)
                            }}
                        />
                        {error && <div style={{ color: 'red', fontSize: '0.7em', paddingTop: "5px" }}>{error}</div>}
                    </div>
                </div>

                <div className={`flex justify-between items-center mb-1.5`}>
                    <Link
                        to="/reset-password"
                        className="font-bold uppercase text-darkBlue text-md font-heading"
                        onClick={onResetPassword}
                    >
                        Reset password
                    </Link>
                </div>

                <div className={`flex justify-end items-center mb-1.5 mt-4`}>
                    <div className="md:w-[100px]">
                        <Button
                            onClick={onUserEmailSubmit}
                            disabled={!emailHasChanges || loading}
                            type="submit"
                        >
                            Updat{loading ? 'ing' : 'e'} Email
                        </Button>

                    </div>
                </div>
            </form>
            {message && (
                <Alert status="success" className="mt-4">
                    <AlertIcon />
                    {message}
                </Alert>
            )}
        </div>
    )
}


export default ProfileSettings;