import React from 'react'
import { DashbordElements } from 'starterComponents'
import { Flex, Stack, Button } from '@chakra-ui/react'
import Visible, { getRole, hasRole, ROLES } from '../../utils/Roles'
import ApiFetch from '../../utils/api'
import { useAuth } from '../../contexts/AuthContext'
import { getSubscriptionStatus } from './utils.js'
import './Subscriptions.css'
import AlertDialogExample from '../ui-components/Confirm'
/**
 * - This component needs to manage:
 *
 * 1. "OLD" subscriptions. These are the ones in DB that are not on braintree. Be aware of enddate to keep giving access to the users to the account page but not not anything else
 * 2. New braintree subscriptions: They can have printed bulletins or not.
 *    1. Printed bulletins.
 *    2. Warranty program.
 *
 * 3. As the "cancelling subscription" will be done from the admin need to understand how to solve this!. Also for reactivating the users.
 */

// TODO: addonId need to be in constants!
const Subscriptions = ({
  subscriptions = [],
  customerID,
  onSubscriptionsChange,
  hasPaymentMethods,
  currentUser,
  shopData,
}) => {
  const [error, setError] = React.useState(null)
  const [updatingBulletins, setUpdatingBulletins] = React.useState(false)
  const [updatingWarrantyProgram, setUpdatingWarrantyProgram] =
    React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false)
  const { updateUser } = useAuth()

  /**
   * Retreieves all the active subscriptions for this braintree user ...
   * @returns
   */
  const getActiveSubsciptions = () => {
    return (
      subscriptions?.filter((subscription) => {
        return subscription && subscription.status === 'Active'
      }) ?? []
    )
  }

  const getPrintedBulletinsAddon = (subscription) => {
    const bulletinsAddons = subscription?.addOns.filter(
      (addOn) => addOn.id === 'printed-bulletin'
    )
    return bulletinsAddons?.length ? bulletinsAddons[0] : null
  }

  const activeSubscriptions = getActiveSubsciptions()

  const onCancelPrintedBulletins = async (e, subscriptionID) => {
    e.preventDefault()
    setUpdatingBulletins(true)
    try {
      let result = await ApiFetch({
        method: 'DELETE',
        path: `/payments/subscription/${subscriptionID}/removePrintedBulletin`,
      })
      if (result && !result.error) {
        const _subscriptions = subscriptions.map((subscription) => {
          if (subscription.id === result.result.subscription.id) {
            subscription = result.result.subscription
          }
          return subscription
        })
        if (result && !result.error) {
          result = await ApiFetch({
            method: 'PUT',
            path: `/companies/${currentUser.shopData.sub_num}`,
            body: {
              bulletin: 'ONLINE',
            },
          })
        }

        onSubscriptionsChange(_subscriptions)
      } else {
        console.log('error: ', result.error)
        setError(result.error)
      }
    } catch (error) {
      console.log('error: ', error)
      setError(error.message)
    } finally {
      setUpdatingBulletins(false)
    }
  }

  const onActivatePrintedBulletins = async (e, subscriptionID) => {
    e.preventDefault()
    setUpdatingBulletins(true)
    try {
      let result = await ApiFetch({
        method: 'PUT',
        path: `/payments/subscription/${subscriptionID}/addPrintedBulletin`,
      })
      if (result && !result.error) {
        const _subscriptions = subscriptions.map((subscription) => {
          if (subscription.id === result.result.subscription.id) {
            subscription = result.result.subscription
          }
          return subscription
        })
        result = await ApiFetch({
          method: 'PUT',
          path: `/companies/${currentUser.shopData.sub_num}`,
          body: {
            bulletin: 'PAPER',
          },
        })
        if (result && !result.error) {
          onSubscriptionsChange(_subscriptions)
        }
      } else {
        console.log('error: ', result.error)
        setError(result.error)
      }
    } catch (error) {
      console.log('error: ', error)
      setError(error.message)
    } finally {
      setUpdatingBulletins(false)
    }
  }

  const formatDate = (date) => new Intl.DateTimeFormat('en-US').format(date)

  const onWarrantyProgramRequestChange = async (activate = true) => {
    setUpdatingWarrantyProgram(true)
    try {
      const result = await ApiFetch({
        method: 'PUT',
        path: `/companies/${currentUser.shopData.sub_num}`,
        body: {
          warrantyProgram: activate ? 1 : 0,
          code: activate ? 'S' : 'W',
        },
      })
      if (result && !result.error) {
        updateUser({
          ...currentUser,
          shopData: {
            ...currentUser.shopData,
            code: activate ? 'S' : 'W',
            warrantyProgram: activate ? 1 : 0,
          },
        })
      } else {
        console.log('error: ', result.error)
        setError(result.error)
      }
    } catch (error) {
      console.log('error: ', error)
      setError(error.message)
    } finally {
      setUpdatingWarrantyProgram(false)
    }
  }

  const onAddNewSubscription = async () => {
    setSubmitting(true)
    try {
      const result = await ApiFetch({
        method: 'PUT',
        path: `/payments/customer/${customerID}/subscribe`,
        body: { role: getRole(currentUser) },
      })
      if (result && !result.error) {
        const _subscription = result.result.subscription
        // Update parente subscriptions.
        onSubscriptionsChange([...subscriptions, _subscription])
      } else {
        console.log('error: ', result.error)
        setError(result.error)
      }
    } catch (error) {
      console.log('error: ', error)
      setError(error.message)
    } finally {
      setSubmitting(false)
    }
  }

  /**
   * Derived from state ... Lefacy users use the "S" code, new ones "warrantyProgram"
   * TODO: I need to implement the "toggle warranty program" and will be on state or update useAuth user once I do tahat
   */
  const shopIsOnWarrantyProgram =
    currentUser.shopData.code === 'S' || currentUser.shopData.warrantyProgram

  const parsedSubscriptionStatus = getSubscriptionStatus(
    activeSubscriptions,
    shopData
  )
  const isRecycler = hasRole(currentUser, ROLES.RECYCLER)

  // Non - braintree subscritions.
  if ('legacy.active' === parsedSubscriptionStatus && !hasPaymentMethods) {
    return (
      <div
        className={
          'bg-white rounded-[20px] px-10 md:px-10  py-8 md:py-8 md:w-6/12 sm:11/12 mt-4'
        }
      >
        <h1 className="uppercase text-lightBlue text-l mb-8">
          Membership Plan
        </h1>
        <div className="mt-4">
          <p>Your membership is active in our previous system.</p>
          <p>
            You will be able to update your membership information during
            renewal
          </p>
        </div>
      </div>
    )
  } else if (parsedSubscriptionStatus === 'legacy.expired') {
    return (
      <div
        className={
          'bg-white rounded-[20px] px-10 md:px-10  py-8 md:py-8 md:w-6/12 sm:11/12 mt-4'
        }
      >
        <h1 className="uppercase text-lightBlue text-l mb-8">
          Membership Plan
        </h1>
        <div className="mt-4">
          <p>Your membership has expired</p>
          {!!hasPaymentMethods && (
            <div>
              <p>Click the button below to activate your membership</p>
              <div>
                Payment of ${isRecycler ? 750 : 75}/month will be automatically
                processed.
              </div>
              <Flex
                justify={{ base: 'center', sm: 'flex-end' }}
                className="mt-5"
              >
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  spacing={5}
                  sx={{ button: { width: 250 } }}
                >
                  <Button
                    variant="lightBlue"
                    isLoading={submitting}
                    loadingText="Subscribing ..."
                    onClick={() => setIsConfirmOpen(!isConfirmOpen)}
                  >
                    Activat{submitting ? 'ing' : 'e'} subscription
                  </Button>
                </Stack>
              </Flex>
            </div>
          )}
          {!hasPaymentMethods && (
            <div>
              Enter your credit card information to renew your membership
            </div>
          )}
          <AlertDialogExample
            isOpen={isConfirmOpen}
            setIsOpen={setIsConfirmOpen}
            onSuccess={onAddNewSubscription}
            messages={{
              title: 'Confirm subscription',
              content: (
                <div>
                  You are subscribing for ${isRecycler ? 750 : 75}/month.
                </div>
              ),
            }}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div
        className={
          'bg-white rounded-[20px] px-10 md:px-10  py-8 md:py-8 md:w-6/12 sm:11/12 mt-4'
        }
      >
        <h1 className="uppercase text-lightBlue text-l mb-8">
          Membership Plan
        </h1>
        {activeSubscriptions.map((subscription) => {
          const printedBulletinsAddon = getPrintedBulletinsAddon(subscription)
          return (
            <div key={subscription.id} id={subscription.id}>
              <DashbordElements title="Plan ID" value={'Annual Membership'} />
              <DashbordElements
                title="Price"
                value={`$${subscription.price}/month`}
              />
              <Visible when={hasRole(currentUser, ROLES.COMPANY)}>
                <DashbordElements
                  title="Printed Bulletins"
                  value={
                    <div className="flex">
                      <div className="mr-2">
                        {printedBulletinsAddon ? `Yes` : `No`}
                      </div>
                      <button
                        className="font-bold uppercase text-lightBlue"
                        style={{ fontSize: '0.75rem' }}
                        onClick={(e) =>
                          printedBulletinsAddon
                            ? onCancelPrintedBulletins(e, subscription.id)
                            : onActivatePrintedBulletins(e, subscription.id)
                        }
                        disabled={updatingBulletins}
                      >
                        {!!updatingBulletins && `Updating`}
                        {!updatingBulletins &&
                          (printedBulletinsAddon
                            ? `Remove bulletins!`
                            : `Add bulletins`)}
                      </button>
                    </div>
                  }
                />
                {printedBulletinsAddon && (
                  <DashbordElements
                    title="Bulletins Price"
                    value={`$${printedBulletinsAddon.amount}/month`}
                  />
                )}
              </Visible>
              <Visible when={hasRole(currentUser, ROLES.COMPANY)}>
                <DashbordElements
                  title="Warranty Program"
                  value={
                    <div className="flex">
                      <div className="mr-2">
                        {shopIsOnWarrantyProgram ? `Yes` : `No`}
                      </div>
                      <button
                        className="font-bold uppercase text-lightBlue"
                        style={{ fontSize: '0.75rem' }}
                        onClick={(e) =>
                          onWarrantyProgramRequestChange(
                            shopIsOnWarrantyProgram ? false : true
                          )
                        }
                        disabled={updatingWarrantyProgram}
                      >
                        {!!updatingWarrantyProgram && `Updating`}
                        {!updatingWarrantyProgram &&
                          (shopIsOnWarrantyProgram ? `OPT OUT` : `OPT IN`)}
                      </button>
                    </div>
                  }
                />
              </Visible>
              <DashbordElements
                title="First billing date"
                value={formatDate(new Date(subscription.firstBillingDate))}
              />
              <DashbordElements
                title="Next Billing date"
                value={formatDate(new Date(subscription.nextBillingDate))}
              />
              <DashbordElements
                title="Next Bill amount"
                value={`$${subscription.nextBillAmount}`}
              />
              {/* <button onClick={(e) => onCancelSubscripion(e, subscription.id)}>
              Cancel Subscription
            </button> */}
            </div>
          )
        })}
        {!activeSubscriptions ||
          (!activeSubscriptions.length && (
            <div className="mt-4">
              <p>You have an expired subscription</p>
              {!!hasPaymentMethods && (
                <div>
                  <p>
                    Click the below button to active your monthly subscription
                  </p>
                  <div>
                    You are subscribing for ${isRecycler ? 750 : 75}/month.
                    Payments will be automatically processed.
                  </div>
                  <Flex
                    justify={{ base: 'center', sm: 'flex-end' }}
                    className="mt-5"
                  >
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      spacing={5}
                      sx={{ button: { width: 250 } }}
                    >
                      <Button
                        variant="lightBlue"
                        isLoading={submitting}
                        loadingText="Subscribing ..."
                        onClick={() => setIsConfirmOpen(!isConfirmOpen)}
                      >
                        Activat{submitting ? 'ing' : 'e'} subscription
                      </Button>
                    </Stack>
                  </Flex>
                </div>
              )}
              {!hasPaymentMethods && (
                <div>
                  To renew your subscription please add your credit card first
                </div>
              )}
              <AlertDialogExample
                isOpen={isConfirmOpen}
                setIsOpen={setIsConfirmOpen}
                onSuccess={onAddNewSubscription}
                messages={{
                  title: 'Confirm subscription',
                  content: (
                    <div>
                      You are subscribing for ${isRecycler ? 750 : 75}/month.
                    </div>
                  ),
                }}
              />
            </div>
          ))}
        {error && alert(error)}
      </div>
    )
  }
}

export default Subscriptions
