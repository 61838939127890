import { Text } from '@chakra-ui/react'
import React from 'react'
import { DashbordElements } from 'starterComponents'
import { Button } from '@chakra-ui/react'
import AddPayment from './AddPayment'
import { getPaymentStatus } from './utils.js'

const PaymentMethods = ({
  paymentMethods,
  onPaymentMethodChange,
  shopData,
}) => {
  const defaultPaymentMethod =
    (paymentMethods &&
      paymentMethods.find((paymentMethod) => paymentMethod.default)) ||
    null

  const [showUpdateCard, setShowUpdateCard] = React.useState(false)

  const onPaymentMethodAdded = (newPaymentMethod) => {
    const _paymentMethods = [newPaymentMethod]
    if (paymentMethods && paymentMethods.length) {
      // onPaymentMethodChange([..._paymentMethods, ...paymentMethods])
      onPaymentMethodChange(_paymentMethods)
      setShowUpdateCard(false)
    } else {
      onPaymentMethodChange(_paymentMethods)
    }
  }
  const parsedPaymentStatus = getPaymentStatus(defaultPaymentMethod, shopData)

  // Check for legacy payment methods. (active vs expired)
  if (parsedPaymentStatus === 'legacy.active') {
    return (
      <div
        className={
          'bg-white rounded-[20px] px-10 md:px-10  py-8 md:py-8 md:w-6/12 sm:11/12 mt-4 md:ml-12'
        }
      >
        <h1 className="uppercase text-lightBlue text-l mb-8">Payment Method</h1>
        <div className="mt-4">
          <p>Your membership is active in our previous system.</p>
          <p>You will be able to update your payment method during renewal</p>
        </div>
      </div>
    )
  } else if (parsedPaymentStatus === 'legacy.expired') {
    return (
      <div
        className={
          'bg-white rounded-[20px] px-10 md:px-10  py-8 md:py-8 md:w-6/12 sm:11/12 mt-4 md:ml-12'
        }
      >
        <h1 className="uppercase text-lightBlue text-l mb-8">Payment Method</h1>
        <div className="mt-4">
          <p>Your membership has expired.</p>
          <p>
            Enter your credit card information below to renew your membership.
          </p>
        </div>
        <div className="mt-4">
          <AddPayment
            onPaymentMethodAdded={onPaymentMethodAdded}
            customerData={{
              contact: shopData.contact,
              email: shopData.companyEmail,
              shop_name: shopData.shop_name,
              braintreeId: shopData.braintreeCustomerId,
            }}
          />
        </div>
      </div>
    )
  }

  // Braintree payment methods. (expired or active)
  if (showUpdateCard) {
    return (
      <div
        className={
          'bg-white rounded-[20px] px-10 md:px-10  py-8 md:py-8 md:w-6/12 sm:11/12 mt-4 md:ml-12'
        }
      >
        <h1 className="uppercase text-lightBlue text-l mb-8">Payment Method</h1>
        <div className="mt-4">
          <p>
            Please add your new credit card to override your default payment
            method
          </p>
        </div>
        <div className="mt-4">
          <AddPayment
            onPaymentMethodAdded={onPaymentMethodAdded}
            customerData={{
              contact: shopData.contact,
              email: shopData.companyEmail,
              shop_name: shopData.shop_name,
              braintreeId: shopData.braintreeCustomerId,
            }}
            defaultPaymentMethod={defaultPaymentMethod}
            onCancel={() => setShowUpdateCard(false)}
          />
        </div>
      </div>
    )
  }
  return (
    <div
      className={
        'bg-white rounded-[20px] px-10 md:px-10  py-8 md:py-8 md:w-6/12 sm:11/12 mt-4 md:ml-12'
      }
    >
      <h1 className="uppercase text-lightBlue text-l mb-8">Payment Method</h1>
      <div>
        <DashbordElements
          title="Card Number"
          value={`Ending in ${defaultPaymentMethod?.last4}`}
        />
        <DashbordElements
          title="Card Type"
          value={
            <div className="flex-row">
              <img
                width="30"
                src={defaultPaymentMethod?.imageUrl}
                className="inline"
                alt=""
              />
              <Text className="ml-2 inline">
                {defaultPaymentMethod?.cardType}
              </Text>
            </div>
          }
        />
        <DashbordElements
          title="Exp Date"
          value={defaultPaymentMethod?.expirationDate}
        />
        {defaultPaymentMethod.expired && (
          <div className={`flex justify-between items-center mb-1.5`}>
            <div className="font-semibold text-[14px] text-red">
              Card is Expired.
            </div>
            <div className="md:w-[200px]">
              <Button
                variant="red"
                onClick={() => {
                  setShowUpdateCard(true)
                }}
              >
                Update!
              </Button>
            </div>
          </div>
        )}
        <Text className="text-sm mt-5">
          To change or remove your card on file please call <br /> (800)
          245-7722
        </Text>
      </div>
    </div>
  )
}

export default PaymentMethods
