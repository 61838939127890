import React from "react"
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button
} from "@chakra-ui/react"

const AlertDialogExample = ({
    isOpen = false,
    setIsOpen = null,
    onSuccess = null,
    messages = {
        title: "Confirm",
        content: "Are you sure?",
    }
}) => {
    const onClose = () => setIsOpen(false)
    const onAccept = () => {
        setIsOpen(false)
        onSuccess && onSuccess()
    }
    const cancelRef = React.useRef()

    return (
        <>
            {/* <Button colorScheme="red" onClick={() => setIsOpen(true)}>
                Delete Customer
            </Button> */}

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {messages.title}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {messages.content}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose} colorScheme="red">
                                Cancel
                            </Button>
                            <Button onClick={onAccept} ml={3}>
                                Confirm
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default AlertDialogExample