import React from 'react'

import braintree from 'braintree-web'
import ApiFetch from '../../utils/api'
import { Flex, Stack, Button } from '@chakra-ui/react'
import { useAuth } from 'contexts/AuthContext'
import './AddPayment.css'

const BRAINTREE_TOKENIZATION_KEY = process.env.GATSBY_BRAINTREE_TOKENIZATION_KEY

const parsedFields = {
  expirationDate: 'Exp. Date',
  cvv: 'CVV',
  number: 'Card Number',
}

const AddPayment = ({
  onPaymentMethodAdded,
  customerData = null,
  onCancel = null,
  defaultPaymentMethod = null,
}) => {
  const [hostedFieldsInstance, setHostedFieldsInstance] = React.useState(null)
  const [submitting, setSubmitting] = React.useState(false)
  const [error, setError] = React.useState('')
  const { currentUser, updateUser } = useAuth()

  const findLabel = (field) => {
    return document.querySelector(
      '.hosted-field--label[for="' + field.container.id + '"]'
    )
  }

  const createHostedFields = () => {
    if (hostedFieldsInstance) return
    braintree.client.create(
      {
        authorization: BRAINTREE_TOKENIZATION_KEY,
      },
      function (err, clientInstance) {
        if (err) {
          console.error('-->', err)
          return
        }

        braintree.hostedFields.create(
          {
            client: clientInstance,
            styles: {
              input: {
                'font-size': '16px',
                'font-family': 'roboto, verdana, sans-serif',
                'font-weight': 'lighter',
                color: 'black',
                display: 'inline',
                width: '75%',
              },
              ':focus': {
                color: 'black',
              },
              '.valid': {
                color: 'black',
              },
              '.invalid': {
                color: 'red',
              },
            },
            fields: {
              number: {
                selector: '#card-number',
                placeholder: '1111 1111 1111 1111',
              },
              cvv: {
                selector: '#cvv',
                placeholder: '111',
              },
              expirationDate: {
                selector: '#expiration-date',
                placeholder: 'MM/YY',
              },
            },
          },
          function (err, hostedFieldsInstance) {
            if (err) {
              console.error('**: ', err)
              return
            }
            setHostedFieldsInstance(hostedFieldsInstance)
            hostedFieldsInstance.on('validityChange', function (event) {
              var field = event.fields[event.emittedBy]
              var label = findLabel(field)
              if (field.isPotentiallyValid) {
                label.classList.remove('invalid')
              } else {
                label.classList.add('invalid')
              }
            })
          }
        )
      }
    )
  }

  React.useEffect(() => {
    createHostedFields()
  }, [])

  // For old users that need to be updated ...
  const onCreateCustomerAndPaymentMethod = (
    contact,
    company,
    email,
    paymentMethodNonce
  ) => {}
  const onSubmit = async (e) => {
    e.preventDefault()
    const state = hostedFieldsInstance.getState()

    if (!checkHostedFieldsFilled()) {
      setError('All fields are required!')
      return
    }
    setSubmitting(true)
    setError('')

    const formValid = Object.keys(state.fields).every(function (key) {
      if (!state.fields[key].isValid) {
        setError(`${parsedFields[key]} is invalid`)
        setSubmitting(false)
      }
      return state.fields[key].isValid
    })

    if (!formValid) {
      return
    }

    hostedFieldsInstance.tokenize(async (err, payload) => {
      if (err) {
        console.error('--ERROR: ', err)
        setSubmitting(false)
        return
      }
      try {
        if (defaultPaymentMethod) {
          const result = await ApiFetch({
            method: 'PUT',
            path: `/payments/customer/${customerData.braintreeId}/credit-card/${defaultPaymentMethod.token}`,
            body: {
              paymentMethodNonce: payload.nonce,
            },
          })
          if (result && !result.error) {
            onPaymentMethodAdded(result.result)
          } else {
            console.log('error: ', result.error)
            setError(result.error)
          }
        } else {
          try {
            const result = await ApiFetch({
              method: customerData.braintreeId ? 'PUT' : 'POST',
              path: customerData.braintreeId
                ? `/payments/customer/${customerData.braintreeId}/payment-method`
                : `/payments/customer`,
              body: {
                ...customerData,
                paymentMethodNonce: payload.nonce,
              },
            })

            if (result && !result.error) {
              const newPaymentMethod = customerData.braintreeId
                ? result.result
                : result.result.paymentMethods[0]
              // if we didn't have a customer on braintree we recieved a new one with its braintreeId so we should update authData to have that field there!
              if (!customerData.braintreeId) {
                updateUser({
                  ...currentUser,
                  shopData: {
                    ...currentUser.shopData,
                    braintreeCustomerId: result.result.id,
                  },
                })
              }
              onPaymentMethodAdded(newPaymentMethod)
            } else {
              console.error('error: ', result.error)
              setError(result?.error)
            }
          } catch (error) {
            console.error('error: ', error)
            setError(error?.message)
          }
        }
      } catch (error) {
        console.error('error: ', error)
        setError(error?.message)
      } finally {
        setSubmitting(false)
      }
    })
  }

  hostedFieldsInstance &&
    hostedFieldsInstance.on('notEmpty', function (event) {
      const allFieldField = checkHostedFieldsFilled()
      allFieldField && setError('')
    })

  const checkHostedFieldsFilled = () => {
    const state = hostedFieldsInstance.getState()
    return Object.keys(state.fields).every(function (key) {
      return !state.fields[key].isEmpty
    })
  }

  return (
    <>
      <form id="cardForm" onSubmit={onSubmit} className="flex flex-col">
        <div className="flex justify-between mb-1.5 items-center">
          <label className="hosted-field--label" htmlFor="card-number">
            Card Number:
          </label>
          <div id="card-number" className="hosted-field"></div>
        </div>
        <div className="flex justify-between mb-1.5 items-center">
          <label className="hosted-field--label" htmlFor="expiration-date">
            Exp. Date:
          </label>
          <div id="expiration-date" className="hosted-field"></div>
        </div>
        <div className="flex justify-between mb-1.5 items-center">
          <label className="hosted-field--label" htmlFor="cvv">
            CVV:
          </label>
          <div id="cvv" className="hosted-field"></div>
        </div>
        {error && (
          <div style={{ color: 'red' }} className="self-end mt-2">
            {error}
          </div>
        )}
        <Flex justify={{ base: 'center', sm: 'flex-end' }} className="mt-5">
          <Stack
            direction={{ base: 'column', sm: 'row' }}
            spacing={5}
            sx={{ button: { width: 150 } }}
          >
            {onCancel && (
              <Button variant="red" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              variant="lightBlue"
              disabled={submitting}
              onClick={onSubmit}
            >
              Sav{submitting ? 'ing' : 'e'} Card
            </Button>
          </Stack>
        </Flex>
      </form>
    </>
  )
}

export default AddPayment
