import React, { useState, useEffect } from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import { Container } from 'starterUiComponents'
import { useAuth } from 'contexts/AuthContext'
import ApiFetch from '../../utils/api'
import Subscriptions from '../../components/payment/Subscriptions'
import PaymentMethods from '../../components/payment/PaymentMethods'
import ProfileSettings from '../../components/account/ProfileSettings'
import useNeedsMembership from '../../hooks/useNeedsMembership'
import Loader from '../../components/ui-components/Loader'

import Visible, {
  hasPermission,
  PERMISSIONS,
  ROLES,
  anyRole,
  getPermissionDeniedReason,
} from '../../utils/Roles'

const AccountPage = (props) => {
  const { currentUser } = useAuth()
  const [state, setState] = useState({
    subscriptions: null,
    paymentMethods: null,
    dataFetched: false,
  })

  const fetchCustomerPaymentData = async () => {
    try {
      if (currentUser?.shopData?.braintreeCustomerId) {
        const result = await ApiFetch({
          method: 'GET',
          path: `/payments/customer/${currentUser.shopData.braintreeCustomerId}`,
        })
        if (result) {
          setState({
            ...state,
            subscriptions: result.result.subscriptions,
            paymentMethods: result.result.paymentMethods,
            dataFetched: true,
          })
        }
      } else {
        setState({
          ...state,
          dataFetched: true,
        })
      }
    } catch (error) {
      console.log('error: ', error)
      setState({
        ...state,
        dataFetched: true,
      })
    }
  }

  useEffect(() => {
    fetchCustomerPaymentData()
  }, [currentUser?.shopData])

  const showLoaderPage = useNeedsMembership() || !state.dataFetched
  if (showLoaderPage) {
    return <Loader message="Loading your data" />
  }

  // In case there are users that can login but have no more permissions
  if (!hasPermission(currentUser, PERMISSIONS.PRIVATE_AREA)) {
    const { reason } = getPermissionDeniedReason(
      currentUser,
      PERMISSIONS.PRIVATE_AREA
    )
    return (
      <Box className="py-12 bg-light">
        <Container
          display={{ base: 'block', md: 'flex' }}
          justifyContent="space-between"
          style={{ gap: '50px' }}
        >
          <Box>
            <Heading fontSize={{ base: 'l', md: '36px' }} fontWeight="light">
              Welcome{' '}
              <strong className="text-lightBlue">
                {currentUser?.shopData?.contact}
              </strong>
            </Heading>
            <Text mb={{ base: 4, sm: 0 }}>
              Uh oh! Your account has limited access due to inactive membership
              status.
            </Text>
            <Text mb={{ base: 4, sm: 0 }}>
              <strong>Reason</strong>: {reason}
            </Text>
          </Box>
          <Box
            className="font-bold font-heading text-md flex flex-col items-start"
            sx={{ p: { mb: 0 } }}
          >
            <Text className="font-bold">
              Subscriber Number {currentUser?.shopData?.sub_num}
            </Text>
            <Text className="font-bold">
              Shop Name: {currentUser?.shopData?.shop_name}
            </Text>
          </Box>
        </Container>
        <Container className="mt-10">
          <ProfileSettings currentUser={currentUser} />
          <Text mb={{ base: 4, sm: 0 }} className="font-bold">
            If you need help or need to cancel your membership:
          </Text>
          <Text mb={{ base: 2, sm: 0 }} className="text-sm">
            Please call in at (800) 245-7722 (you will get a call back at the
            shop phone number on record) or email us from the email address on
            record.
          </Text>
        </Container>
      </Box>
    )
  }

  return (
    <Box className="py-12 bg-light">
      <Container
        display={{ base: 'block', md: 'flex' }}
        justifyContent="space-between"
      >
        <Box>
          <Heading fontSize={{ base: 'l', md: '36px' }} fontWeight="light">
            Welcome{' '}
            <strong className="text-lightBlue">
              {currentUser?.shopData?.contact}
            </strong>
          </Heading>
          <Text mb={{ base: 4, sm: 0 }}>
            Access your personal <strong>Membership Benefits</strong> here.
          </Text>
        </Box>
        <Box
          className="font-bold font-heading text-md flex flex-col items-end"
          sx={{ p: { mb: 0 } }}
        >
          <Text className="font-bold">
            Subscriber Number {currentUser?.shopData?.sub_num}
          </Text>
          <Text className="font-bold">
            Shop Name: {currentUser?.shopData?.shop_name}
          </Text>
        </Box>
      </Container>

      {/* Braintree & Firebase ... */}
      <Visible when={anyRole(currentUser, [ROLES.COMPANY, ROLES.RECYCLER])}>
        <Container className="flex flex-col md:flex-row justify-between mt-6">
          <Subscriptions
            subscriptions={state.subscriptions}
            hasPaymentMethods={state?.paymentMethods?.length ?? false}
            customerID={currentUser?.shopData?.braintreeCustomerId}
            currentUser={currentUser}
            shopData={currentUser?.shopData}
            onSubscriptionsChange={(subscriptions) => {
              setState({
                ...state,
                subscriptions: subscriptions,
              })
            }}
          />
          <PaymentMethods
            paymentMethods={state.paymentMethods}
            customerID={currentUser?.shopData?.braintreeCustomerId}
            shopData={currentUser?.shopData}
            onPaymentMethodChange={(paymentMethods) => {
              setState({
                ...state,
                paymentMethods: paymentMethods,
              })
            }}
          />
        </Container>

        <Container className="mt-10">
          <ProfileSettings currentUser={currentUser} />
          <Text mb={{ base: 4, sm: 0 }} className="font-bold">
            If you need help or need to cancel your membership:
          </Text>
          <Text mb={{ base: 2, sm: 0 }} className="text-sm">
            Please call in at (800) 245-7722 (you will get a call back at the
            shop phone number on record) or email us from the email address on
            record.
          </Text>
        </Container>
      </Visible>
    </Box>
  )
}

export default AccountPage
