const dateToTime = (date) => new Date(date).getTime();
const expirationDateIsDue = (date) => dateToTime(date) <= new Date().getTime();
/**
 * We can have:
 * - Normal payments mehthods on braintree
 * - Legacy payment methods active
 * - Legacy payment methods not active
 * - Expired payment methods on braintree (TODO: Not implemented yet)
 */
export const getPaymentStatus = (defaultPaymentMethod, shopData) => {
    if (defaultPaymentMethod) {
        // TODO: check expiration date
        return "active"
    } else {
        if (shopData.expired) {
            return "legacy.expired"
        }
        else if (expirationDateIsDue(shopData.ren_date)) {
            return "legacy.expired"
        } else {
            return "legacy.active"
        }
    }
}

/**
 * We can have:
 * - Normal subscriptions mehthods on braintree
 * - Legacy subscriptions methods active
 * - Legacy subscriptions methods not active
 */
export const getSubscriptionStatus = (subscriptions, shopData) => {
    if (subscriptions && subscriptions.length) {
        // TODO: check expiration date
        return "active"
    } else {
        if (shopData.expired) {
            return "legacy.expired"
        }
        else if (expirationDateIsDue(shopData.ren_date)) {
            return "legacy.expired"
        } else {
            return "legacy.active"
        }
    }
}